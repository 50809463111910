import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import Backend from "../Backend/Backend";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import PrinterSheet from "./PrinterSheet";
import { BluetoothSerial } from "@ionic-native/bluetooth-serial";
import { InputTextarea } from "primereact/inputtextarea";
import Header from "../Component/Header";
global.Buffer = require("buffer").Buffer;

const backend = new Backend();
export default function Setting() {
  const [width, height] = useWindowSize();
  const [selectBluetooth, setSelectBluetooth] = React.useState([]);

  const [printCustomer, setPrintCustomer] = React.useState([]);
  const [printMobile, setPrintMobile] = React.useState([]);
  const [printPopup, setPrintPopup] = React.useState([]);
  const [printGapLines, setPrintGapLines] = React.useState([]);
  const [printAddress, setPrintAddress] = React.useState([]);

  const [printDate, setPrintDate] = React.useState([]);
  const [printFooter, setPrintFooter] = React.useState("");

  const [mainPrinter, setMainPrinter] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [searchPrinterSheet, setSearchPrinterSheet] = React.useState(false);

  const dropDownArray = [
    { name: "Off", code: "0" },
    { name: "Bluetooth", code: "1" },
  ];

  const dropDownArrayOnOff = [
    { name: "Off", code: "0" },
    { name: "On", code: "1" },
  ];

  const dropDownArrayDate = [
    { name: "Off", code: "0", format: "" },
    { name: "Only Date", code: "2", format: "DD-MM-YYYY" },
    { name: "Only Time", code: "3", format: "hh:mm:ss" },
    { name: "Full Date Time", code: "1", format: "DD-MM-YYYY hh:mm:ss" },
  ];

  const dropDownArrayGap = [
    { name: "1 Line", code: "1" },
    { name: "2 Lines", code: "2" },
    { name: "3 Lines", code: "3" },
    { name: "4 Lines", code: "4" },
    { name: "5 Lines", code: "5" },
  ];

  React.useEffect(() => {
    if (localStorage.getItem("bluetooth")) {
      setMainPrinter({ name: "Bluetooth", code: "1" });
      setSelectBluetooth(JSON.parse(localStorage.getItem("bluetooth")));
    }
    if (localStorage.getItem("printMobile") == "1") {
      setPrintMobile({ name: "On", code: "1" });
    }
    if (localStorage.getItem("printPopup") == "1") {
      setPrintPopup({ name: "On", code: "1" });
    }
    if (localStorage.getItem("printAddress") == "1") {
      setPrintAddress({ name: "On", code: "1" });
    }
    if (localStorage.getItem("printDate")) {
      var localStorageValue = localStorage.getItem("printDate");

      // Find the item in the array that matches the condition
      var selectedPrintMobile = dropDownArrayDate.find(
        (item) => item.format === localStorageValue
      );

      // Set the state with the selected item
      setPrintDate(selectedPrintMobile);
    }
    if (localStorage.getItem("printGapLines")) {
      setPrintGapLines({
        name: localStorage.getItem("printGapLines") + " Lines",
        code: localStorage.getItem("printGapLines"),
      });
    }
    if (localStorage.getItem("printFooter")) {
      setPrintFooter(localStorage.getItem("printFooter"));
    }
  }, []);

  const bluetoothTest = () => {
    if (selectBluetooth.length <= 0) {
      alert("Please Select Bluetooth");
    } else {
      BluetoothSerial.isEnabled().then(
        (e) => {
          // alert(e);
          bluetoothIsConnected();
        },
        (e) => {
          // alert(e);
          enableBluetooth();
        }
      );
    }
  };

  const enableBluetooth = () => {
    BluetoothSerial.enable().then(
      (e) => {
        // alert(e);
        bluetoothIsConnected();
      },
      (e) => {
        alert("Please Turn On Bluetooth Manually");
        // this.connectBluetooth();
      }
    );
  };

  const bluetoothIsConnected = () => {
    BluetoothSerial.isConnected().then(
      (e) => {
        // alert(e);
        testPrintBluetooth();
      },
      (e) => {
        // alert(e);
        connectBluetooth();
      }
    );
  };

  const connectBluetooth = () => {
    BluetoothSerial.connect(selectBluetooth.address).subscribe(
      (e) => testPrintBluetooth(),
      (r) => console.log(r)
    );
  };

  const testPrintBluetooth = () => {
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write(Buffer.from("1B2109", "hex"));
    BluetoothSerial.write(Buffer.from("Test Sucess\n\n", "ascii"));

    save_to_local();
  };

  const save_to_local = () => {
    localStorage.setItem("bluetooth", JSON.stringify(selectBluetooth));
  };

  const handleMobile = (data) => {
    setPrintMobile(data);
    localStorage.setItem("printMobile", data.code);
  };
  const handlePopup = (data) => {
    setPrintPopup(data);
    localStorage.setItem("printPopup", data.code);
  };

  const handleCustomer = (data) => {
    setPrintCustomer(data);
    localStorage.setItem("printCustomer", data.code);
  };

  const handleAddress = (data) => {
    setPrintAddress(data);
    localStorage.setItem("printAddress", data.code);
  };

  const handleDate = (data) => {
    setPrintDate(data);
    localStorage.setItem("printDate", data.format);
  };

  const handleGapLines = (data) => {
    setPrintGapLines(data);
    localStorage.setItem("printGapLines", data.code);
  };

  const handleFooter = (data) => {
    if (data.length > 32) {
      alert("Only 32 Characters allowed");
      setPrintFooter("");
    } else {
      setPrintFooter(data);
      localStorage.setItem("printFooter", data);
    }
  };

  const handleStatus = (value) => {
    if (value.code == 0) {
      localStorage.removeItem("bluetooth");
      setMainPrinter({});
    } else {
      setMainPrinter(value);
      setSearchPrinterSheet(true);
    }
  };

  return (
    <>
      <div style={{ height: height, width: width }}>
        <Header name={"Settings"} />

        <div
          style={{
            borderRadius: 10,
            margin: 5,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
              justifyContent: "space-between",
            }}
          >
            Re-Print Popup
            <div style={{ width: "50%" }}>
              <Dropdown
                value={printPopup}
                options={dropDownArrayOnOff}
                onChange={(e) => handlePopup(e.target.value)}
                optionLabel="name"
                placeholder="Off"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            Print Gap after bill
            <div style={{ width: "50%" }}>
              <Dropdown
                value={printGapLines}
                options={dropDownArrayGap}
                onChange={(e) => handleGapLines(e.target.value)}
                optionLabel="name"
                placeholder="Off"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
              justifyContent: "space-between",
            }}
          >
            Print Customer
            <div style={{ width: "50%" }}>
              <Dropdown
                value={printCustomer}
                options={dropDownArrayOnOff}
                onChange={(e) => handleCustomer(e.target.value)}
                optionLabel="name"
                placeholder="Off"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
              justifyContent: "space-between",
            }}
          >
            Print Mobile Number
            <div style={{ width: "50%" }}>
              <Dropdown
                value={printMobile}
                options={dropDownArrayOnOff}
                onChange={(e) => handleMobile(e.target.value)}
                optionLabel="name"
                placeholder="Off"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            Print Address on bill
            <div style={{ width: "50%" }}>
              <Dropdown
                value={printAddress}
                options={dropDownArrayOnOff}
                onChange={(e) => handleAddress(e.target.value)}
                optionLabel="name"
                placeholder="Off"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            Print Date on bill
            <div style={{ width: "50%" }}>
              <Dropdown
                value={printDate}
                options={dropDownArrayDate}
                onChange={(e) => handleDate(e.target.value)}
                optionLabel="name"
                placeholder="Off"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            Print Footer
            <div style={{ width: "50%" }}>
              <InputTextarea
                value={printFooter}
                onChange={(e) => handleFooter(e.target.value)}
                optionLabel="name"
                placeholder="Write Here Upto 32 Character"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              borderRadius: 10,
              margin: 5,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                color: "black",
                fontSize: 16,
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Printer
              <div style={{ width: "50%" }}>
                <Dropdown
                  value={mainPrinter}
                  options={dropDownArray}
                  onChange={(e) => handleStatus(e.target.value)}
                  optionLabel="name"
                  placeholder="Off"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                label={
                  selectBluetooth?.name == null ||
                  selectBluetooth?.name == undefined
                    ? "Search Printer"
                    : selectBluetooth?.name
                }
                className="p-button p-button-sm"
                // style={{ backgroundColor: "black" }}
                onClick={() => setSearchPrinterSheet(true)}
              />
              <Button
                label="Save & Test Print"
                className="p-button p-button-sm"
                // style={{ backgroundColor: "black" }}v
                onClick={() => bluetoothTest()}
              />
            </div>
          </div>
        </div>
        <BottomSheet open={searchPrinterSheet}>
          <PrinterSheet
            selectBluetooth={(bluetooth) => setSelectBluetooth(bluetooth)}
            closeSheet={() => setSearchPrinterSheet(false)}
          />
        </BottomSheet>
      </div>
    </>
  );
}
