import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { useNavigate } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import Backend from "../Backend/Backend";
import Header from "../Component/Header";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import moment from "moment/moment";

const backend = new Backend();

export default function Premium() {
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  const [planData, setPlanData] = React.useState([]);
  const [adminData, setAdminData] = React.useState([]);
  const [couponCode, setCouponCode] = React.useState("");
  const [couponMsg, setCouponMsg] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    get_premium_plan();
  }, []);

  const get_premium_plan = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend
      .get_premium_plan(data)
      .then((r) => {
        if (r.error == "False") {
          setPlanData(r.plans);
          setAdminData(r.data);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      })
      .catch((err) => alert(err));
  };

  const redeem_coupon_code = () => {
    if (couponCode.length <= 0) {
      window.alert("Invalid promo code");
    } else {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        coupon_code: couponCode,
      };
      backend
        .redeem_coupon_code(data)
        .then((r) => {
          if (r.error == "False") {
            get_premium_plan();
            window.alert(r.message);
            setCouponMsg(r.message);
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        })
        .catch((err) => alert(err))
        .finally(() => setCouponCode(""));
    }
  };

  return (
    <div style={{ height: height, width: width }}>
      <Header name="Premium" />
      <div className="bgp" style={{ height: "100%", paddingTop: 10 }}>
        {/* <div
          style={{
            height: 50,
            backgroundColor: "yellow",
            borderWidth: 2,
            borderRadius: 5,
            borderStyle: "dashed",
            borderColor: "black",
            marginRight: 20,
            marginLeft: 20,
            marginTop: 10,
            display: "flex",
            padding: 5,
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LuBadgePercent size={28} />
        </div> */}

        <div
          style={{
            borderWidth: 1,
            backgroundColor: "#ffffff",
            borderRadius: 5,
            borderStyle: "solid",
            borderColor: "black",
            marginRight: 20,
            marginLeft: 20,
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            padding: 10,
            gap: 10,
            color: "gray",
            fontSize: 14,
            fontWeight: "500",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottomColor: "black",
              borderBottomStyle: "solid",
              borderBottomWidth: 1,
            }}
          >
            <span
              style={{
                fontWeight: "500",
                color: "black",
                textTransform: "uppercase",
              }}
            >
              {adminData?.name}
            </span>
            <span style={{ color: adminData?.premium == 1 ? "green" : "red" }}>
              {adminData?.premium == 1 ? "Active" : "Expired"}{" "}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Account Name</span>
            <span>{adminData?.company_name} </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Mobile Number</span>
            <span>{adminData?.mobile} </span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Plan</span>
            <span>{adminData?.plan_type} </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Expiry</span>
            <span>{moment(adminData?.expiry_date).format("DD-MMM-YYYY")} </span>
          </div>
          <div
            style={{
              padding: 10,
              paddingTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Button
              //   onClick={() => add_captain()}
              label="Extend License"
              severity="warning"
              raised
              style={{
                marginTop: 10,
                width: "100%",
                color: "#ffffff",
              }}
            />
          </div>
        </div>

        <div
          style={{
            borderWidth: 1,
            backgroundColor: "#ffffff",
            borderRadius: 5,
            borderStyle: "solid",
            borderColor: "black",
            marginRight: 20,
            marginLeft: 20,
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            padding: 10,
            gap: 10,
            color: "gray",
            fontSize: 14,
            fontWeight: "500",
          }}
        >
          <div
            style={{
              padding: 10,
              paddingTop: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            <InputText
              onChange={(e) => setCouponCode(e.target.value)}
              style={{ marginTop: 5, width: "100%" }}
              placeholder="Enter coupon code"
              value={couponCode}
            />

            <Button
              onClick={() => redeem_coupon_code()}
              label="Redeem"
              style={{ marginTop: 5, width: "40%" }}
            />
          </div>
          {couponMsg && (
            <div
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "green",
              }}
            >
              {couponMsg}
            </div>
          )}
        </div>

        <div
          style={{
            borderWidth: 1,
            backgroundColor: "#ffffff",
            borderRadius: 5,
            borderStyle: "solid",
            borderColor: "black",
            marginRight: 20,
            marginLeft: 20,
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            padding: 10,
            gap: 10,
            color: "gray",
            fontSize: 14,
            fontWeight: "500",
          }}
        >
          <div
            style={{
              padding: 10,
              paddingTop: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            <div>Monthly Plan</div>

            <Button
              // onClick={() => redeem_coupon_code()}
              label="Purchase"
              style={{ marginTop: 5, width: "40%" }}
            />
          </div>
        </div>

        <div
          style={{
            borderWidth: 1,
            backgroundColor: "#ffffff",
            borderRadius: 5,
            borderStyle: "solid",
            borderColor: "black",
            marginRight: 20,
            marginLeft: 20,
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            padding: 10,
            gap: 10,
            color: "gray",
            fontSize: 14,
            fontWeight: "500",
          }}
        >
          <div
            style={{
              padding: 10,
              paddingTop: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            <div>Yearly Plan</div>

            <Button
              // onClick={() => redeem_coupon_code()}
              label="Purchase"
              style={{ marginTop: 5, width: "40%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
