import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { BsPrinterFill } from "react-icons/bs";
import { MdArrowBackIos } from "react-icons/md";
import Backend from "../Backend/Backend";
import { Badge } from "primereact/badge";
import { BluetoothSerial } from "@ionic-native/bluetooth-serial";
import moment from "moment";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
global.Buffer = require("buffer").Buffer;
const backend = new Backend();

export default function BillScreen() {
  const [width, height] = useWindowSize();
  const [loading, setLoading] = React.useState(false);
  const [bill, SetBill] = React.useState(
    JSON.parse(localStorage.getItem("TEMPBILL"))
  );
  const [pdfShow, setPdfShow] = React.useState(false);
  const [selectBluetooth, setSelectBluetooth] = React.useState([]);

  const scrollheight = height - 200;

  const delete_invoice = (id) => {
    if (id <= 0) {
      window.alert("Invalid Invoice Id. Please try again after reload!");
    }
    if (window.confirm("Do you really want to delete this invoice?")) {
      if (!loading) {
        setLoading(true);
        let data = {
          authtoken: localStorage.getItem("admin_token"),
          admin_id: localStorage.getItem("admin_id"),
          invoice_id: id,
        };
        backend.delete_invoice(data).then((r) => {
          if (r.error == "False") {
            // load_sales_report();
            window.history.back();
            window.alert(r.message);
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      }
    }
  };

  const options = {
    // default is `save`
    method: "open",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.NORMAL,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "A4",
      // default is 'portrait'
      orientation: "potrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };
  // you can use a function to return the target element besides using React refs
  const getTargetElement = () => document.getElementById("content-id");
  const MyComponent = () => {
    var adminData = JSON.parse(localStorage.getItem("admin_data"));
    const address = adminData.address.split("|,|");

    return (
      <div
        style={{
          flex: 1,
          fontWeight: "500",
          display: "flex",
        }}
      >
        {/* Display the address or any other content here */}
        {address.map((line, index) => (
          <span key={index}>
            {line}
            {index < address.length - 1 ? ", " : ""}
          </span>
        ))}
      </div>
    );
  };

  React.useEffect(() => {
    if (localStorage.getItem("bluetooth")) {
      setSelectBluetooth(JSON.parse(localStorage.getItem("bluetooth")));
    }
  }, []);

  const bluetoothTest = () => {
    if (selectBluetooth.length <= 0) {
      alert("Please select printer or turn on your bluetooth");
    } else {
      BluetoothSerial.isEnabled().then(
        (e) => {
          // alert(e);
          bluetoothIsConnected();
        },
        (e) => {
          // alert(e);
          enableBluetooth();
        }
      );
    }
  };

  const enableBluetooth = () => {
    BluetoothSerial.enable().then(
      (e) => {
        // alert(e);
        bluetoothIsConnected();
      },
      (e) => {
        alert("Please Turn On Bluetooth Manually");
        // this.connectBluetooth();
      }
    );
  };

  const bluetoothIsConnected = () => {
    BluetoothSerial.isConnected().then(
      (e) => {
        // alert(e);
        testPrintBluetooth();
      },
      (e) => {
        // alert(e);
        connectBluetooth();
      }
    );
  };

  const connectBluetooth = () => {
    BluetoothSerial.connect(selectBluetooth.address).subscribe(
      (e) => testPrintBluetooth(),
      (r) => console.log(r)
    );
  };

  const testPrintBluetooth = () => {
    var adminData = JSON.parse(localStorage.getItem("admin_data"));
    var printItems = bill.items;
    var customer = JSON.parse(bill.customer);

    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write(Buffer.from("1B2130", "hex"));
    BluetoothSerial.write(adminData.company_name + "\n");
    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    if (localStorage.getItem("printMobile") == "1") {
      BluetoothSerial.write("Mob:" + adminData.mobile + "\n");
    }
    if (parseFloat(bill.gst) > 0) {
      BluetoothSerial.write("GST NO:" + adminData.gst_no + "\n");
    }
    if (localStorage.getItem("printAddress") == "1") {
      var address = adminData.address.split("|,|");
      if (address[0]) {
        BluetoothSerial.write(address[0] + "\n");
      }
      if (address[1]) {
        BluetoothSerial.write(address[1] + "\n");
      }
      if (address[2]) {
        BluetoothSerial.write(address[2] + "\n");
      }
    }
    BluetoothSerial.write("-".repeat(30) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));

    BluetoothSerial.write("BILL # " + bill.bill_no + "\n");

    if (localStorage.getItem("printDate") != "") {
      BluetoothSerial.write(
        "DATE: " +
          moment(bill.invoice_date).format(localStorage.getItem("printDate")) +
          "\n"
      );
    }

    if (localStorage.getItem("printCustomer") == "1") {
      if (customer.customer_name != "") {
        BluetoothSerial.write("Name: " + customer.customer_name + "\n");
      }
      if (customer.customer_mobile != "") {
        BluetoothSerial.write("Mob: " + customer.customer_mobile + "\n");
      }

      if (customer.customer_details != "") {
        BluetoothSerial.write("Detail: " + customer.customer_details + "\n");
      }
    }

    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));
    BluetoothSerial.write(
      "Item Name" +
        " ".repeat(6) +
        " " +
        "Qty" +
        " " +
        "Rate" +
        " " +
        "    " +
        "Amt" +
        "\n"
    );
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));

    printItems.map((r) => {
      r.qty = parseFloat(r.qty);
      r.amt = parseFloat(r.amount);
      r.rate = parseFloat(r.product_rate);
      var qtystr = "";
      var ratestr = "";
      var amtstr = "";
      var regular = true;

      var varnamelength = r.product_name.length;
      var qtylen = r.qty.toString().length;

      var ratelen = r.rate.toString().length;
      var amtlen = r.amt.toString().length;
      var name1 = "";
      var name2 = "";
      if (varnamelength > 15) {
        name1 = r.product_name.substring(0, 15);
        name2 = r.product_name.substring(15, 30);
      } else {
        name1 = r.product_name;
      }

      if (name1.length == 15) {
      } else {
        name1 = name1 + " ".repeat(15 - name1.length);
      }

      //name 15 characters

      if (qtylen > 4) {
        regular = false;
        qtystr = r.qty.toString();
      } else {
        if (qtylen == 4) {
          qtystr = r.qty.toString();
        } else {
          qtystr = r.qty.toString() + " ".repeat(4 - r.qty.toString().length);
        }
      }

      if (ratelen > 4) {
        regular = false;
        ratestr = r.rate.toString();
      } else {
        if (ratelen == 4) {
          ratestr = r.rate.toString();
        } else {
          ratestr =
            r.rate.toString() + " ".repeat(4 - r.rate.toString().length);
        }
      }

      if (r.amt.toString().length > 6) {
        regular = false;
        amtstr = r.amt.toString();
      } else {
        if (r.amt.toString().length == 6) {
          amtstr = r.amt.toString();
        } else {
          amtstr = " ".repeat(6 - r.amt.toString().length) + r.amt.toString();
        }
      }

      if (regular == true) {
        BluetoothSerial.write(
          name1 + " " + qtystr + " " + ratestr + " " + amtstr + "\n"
        );
        if (name2 != "") {
          BluetoothSerial.write(name2 + "\n");
        }
      } else {
        BluetoothSerial.write(name1 + " " + name2 + "\n");
        BluetoothSerial.write(
          qtystr +
            " x " +
            ratestr +
            " = " +
            parseFloat(amtstr).toFixed(2) +
            "\n"
        );
      }
      regular = true;
    });

    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    BluetoothSerial.write(Buffer.from("1B6102", "hex"));
    BluetoothSerial.write(Buffer.from("1B2161", "hex"));
    BluetoothSerial.write("Total : " + bill.total + "\n");
    if (parseFloat(bill.discount) > 0) {
      BluetoothSerial.write("Discount : " + bill.discount + "\n");
    }

    if (parseFloat(bill.gst) > 0) {
      BluetoothSerial.write(
        "Gst  " +
          "@ " +
          parseInt(adminData.gst_percent) +
          "%  :" +
          bill.gst +
          "\n"
      );
    }
    // if (parseFloat(datas.gst) > 0) {
    //   BluetoothSerial.write("CGST @ 2.50 : " + datas.cgst + "\n");
    //   BluetoothSerial.write("SGST @ 2.50 : " + datas.sgst + "\n");
    // }
    if (parseFloat(bill.roundoff) != 0) {
      BluetoothSerial.write(
        "RoundOff : " + parseFloat(bill.roundoff).toFixed(2) + "\n"
      );
    }

    BluetoothSerial.write("Net : " + bill.net_amount + "\n");
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");

    if (localStorage.getItem("printFooter")) {
      let footer = localStorage.getItem("printFooter");
      BluetoothSerial.write(footer + "\n");
    }
    // var settings = JSON.parse(this.state.bluetoothsetting);
    var settings = {
      largefont: false,
      workerid: false,
      time_of_order: false,
      linefeeds:
        localStorage.getItem("printGapLines") > 0
          ? localStorage.getItem("printGapLines")
          : 3,
    };

    if (settings.linefeeds > 0) {
      for (var i = 0; i < settings.linefeeds; i++) {
        BluetoothSerial.write(Buffer.from("\n ", "ascii"));
      }
    }

    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));
  };

  const downloadPdf = () => {
    setPdfShow(true);
    setTimeout(() => {
      generatePDF(getTargetElement, options);
      setPdfShow(false);
    }, 100);
  };

  const groupedGstAmounts = bill.items.reduce((acc, product) => {
    const { gst, amount = 0, product_rate, qty } = product; // Ensure `amount` has a default value
    const gstAmount =
      product.gstAmount ??
      product_rate * parseFloat(qty) -
        ((product_rate * parseFloat(qty)) / (100 + parseFloat(gst))) * 100;

    // Find an existing entry for the same GST value
    const existingGst = acc.find(
      (item) => Object.keys(item)[0] === String(gst)
    );

    if (existingGst) {
      // Add to the existing GST amount
      existingGst[gst] += Number(gstAmount);
    } else {
      // Create a new entry for the GST
      acc.push({ [gst]: Number(gstAmount) });
    }

    return acc;
  }, []);

  const gstElements =
    JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 2 &&
    groupedGstAmounts.length > 0
      ? groupedGstAmounts.map((item, index) => {
          const gstValue = Object.keys(item)[0]; // Get the GST percentage (e.g., "12.00")
          const gstAmount = item[gstValue]; // Get the corresponding GST amount

          if (parseFloat(gstValue) > 0) {
            // Construct your message
            return (
              <>
                <div key={index} style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{ flex: 3, fontWeight: "500", display: "flex" }}
                    ></div>
                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></div>

                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        // padding: "5px 0",
                      }}
                    >
                      Cgst @ {parseFloat(gstValue) / 2}% :
                    </div>

                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "end",
                        fontWeight: "bold",
                        // padding: "10px 0",
                      }}
                    >
                      {parseFloat(parseFloat(gstAmount) / 2).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div key={index} style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{ flex: 3, fontWeight: "500", display: "flex" }}
                    ></div>
                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></div>

                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        // padding: "5px 0",
                      }}
                    >
                      Sgst @ {parseFloat(gstValue) / 2}% :
                    </div>

                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "end",
                        fontWeight: "bold",
                        // padding: "10px 0",
                      }}
                    >
                      {parseFloat(parseFloat(gstAmount) / 2).toFixed(2)}
                    </div>
                  </div>
                </div>
              </>
            );
          }
          return null; // Return null if gstValue is not greater than 0
        })
      : null;

  return (
    <div style={{ height: height, width: width, overflowY: "hidden" }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
          // onClick={() => (window.location = "/reports")}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>Bill Screen</div>
          <div style={{ fontSize: 12, color: "var(--red-400)" }}>
            {bill.status == "0" ? "DELETED" : ""}
          </div>
        </div>

        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            color: "gray",
            paddingRight: 10,
          }}
        >
          <Badge
            onClick={() => downloadPdf()}
            value={"PDF"}
            severity={"warning"}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              height: 30,
              borderRadius: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>

        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            color: "gray",
            paddingRight: 10,
          }}
        >
          <Badge
            onClick={() => bluetoothTest()}
            value={"REPRINT"}
            severity={"primary"}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              height: 30,
              borderRadius: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>

        {bill.status != "0" ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              color: "gray",
              paddingRight: 10,
            }}
            //   onClick={() => delete_all_invoice()}
            // onClick={() => (window.location = "/reports")}
          >
            <Badge
              onClick={() => delete_invoice(bill.id)}
              value={"DELETE"}
              severity={"danger"}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                height: 30,
                borderRadius: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        ) : null}
      </div>

      <div style={{ height: 60, display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
            flex: 1,

            borderRightStyle: "solid",
            borderRightWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
            Net Amount
          </div>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "var(--primary-color)",
            }}
          >
            {bill.net_amount}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
            flex: 1,

            borderRightStyle: "solid",
            borderRightWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
            Invoice No
          </div>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "var(--primary-color)",
            }}
          >
            {bill.bill_no}
          </div>
        </div>
      </div>

      <div style={{ height: 60, display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
            flex: 1,

            borderRightStyle: "solid",
            borderRightWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
            {"Gst "}
          </div>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "var(--primary-color)",
            }}
          >
            {bill.gst}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
            flex: 1,

            borderRightStyle: "solid",
            borderRightWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
            {"Round Off "}
          </div>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "var(--primary-color)",
            }}
          >
            {bill.roundoff}
          </div>
        </div>
      </div>

      <div
        style={{
          padding: 5,
          paddingRight: 10,
          background: "var(--primary-color)",
          color: "white",
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: 40 }}>SR</div>
          <div style={{ flex: 2, textAlign: "left" }}>Product Name</div>
          <div style={{ flex: 1 }}>Qty</div>
          <div style={{ flex: 1 }}>Rate</div>
          <div style={{ flex: 1, textAlign: "right" }}>Amt</div>
        </div>
      </div>
      <div
        style={{
          padding: 5,
          paddingRight: 10,
          height: scrollheight,
          overflowY: "scroll",
        }}
      >
        {bill.items.map((i, t) => (
          <div style={{ display: "flex" }}>
            <div style={{ width: 40 }}>{i.serial}</div>
            <div style={{ flex: 2, textAlign: "left" }}>{i.product_name}</div>
            <div style={{ flex: 1 }}>{parseFloat(i.qty)}</div>
            <div style={{ flex: 1 }}>{parseFloat(i.product_rate)}</div>
            <div style={{ flex: 1, textAlign: "right" }}>
              {parseFloat(i.amount)}
            </div>
          </div>
          //
        ))}
      </div>

      <div hidden={!pdfShow} style={{ zIndex: -99999 }}>
        <div
          className="invoice-box"
          style={{ position: "absolute" }}
          id="content-id"
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                fontSize: 35,
              }}
            >
              {JSON.parse(localStorage.getItem("admin_data"))?.company_name}
            </div>
            <div
              style={{
                flex: 1,
                height: 80,
                display: "flex",
                fontSize: 25,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              INVOICE
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div>
                <b>BILLED TO</b>
              </div>
              <div>{JSON.parse(bill?.customer)?.customer_name}</div>
              <div>{JSON.parse(bill?.customer)?.customer_mobile}</div>
              <div>{JSON.parse(bill?.customer)?.customer_details}</div>
              <div>
                {parseFloat(JSON.parse(bill?.customer)?.customer_gst) > 0
                  ? "GST : " + JSON.parse(bill?.customer)?.customer_gst
                  : null}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <div>Details</div>
              <div>Invoice No. : {bill?.bill_no}</div>
              <div>
                {"GST : " +
                  JSON.parse(localStorage.getItem("admin_data"))?.gst_no}
              </div>
              <div>
                Date : {moment(bill?.invoice_date).format("DD-MMM-YYYY")}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: 20 }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
                padding: "10px 0",
              }}
            >
              <div style={{ flex: 3, display: "flex" }}>
                <b>Item</b>
              </div>

              <div
                style={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                <b>Qty</b>
              </div>

              <div
                style={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                <b>Price</b>
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <b>Total</b>
              </div>
            </div>
          </div>
          {bill.items.map((i, t) => (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  borderBottom: "1px solid gray",
                  padding: "10px 0",
                }}
              >
                <div style={{ flex: 3, fontWeight: "500", display: "flex" }}>
                  {i.product_name}
                </div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {i.qty}
                </div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {parseFloat(i.product_rate).toFixed(2)}
                </div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {parseFloat(i.amount).toFixed(2)}
                </div>
              </div>
            </div>
          ))}

          {/* subtotal */}
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                // borderBottom: "1px solid gray",
                padding: "10px 0",
              }}
            >
              <div
                style={{ flex: 3, fontWeight: "500", display: "flex" }}
              ></div>
              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                SubTotal
              </div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: "bold",
                }}
              >
                {bill.total}
              </div>
            </div>
          </div>
          {bill.gst > 0 ? (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ flex: 3, fontWeight: "500", display: "flex" }}
                ></div>
                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    padding: "10px 0",
                  }}
                >
                  GST
                </div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: "bold",
                    padding: "10px 0",
                  }}
                >
                  {bill.gst}
                </div>
              </div>
            </div>
          ) : null}
          {gstElements}
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ flex: 3, fontWeight: "500", display: "flex" }}
              ></div>
              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  borderTop: "2px solid black",
                  padding: "10px 0",
                }}
              >
                Net Total
              </div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: "bold",
                  borderTop: "2px solid black",
                  padding: "10px 0",
                }}
              >
                {bill?.net_amount}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                padding: "10px 0",
              }}
            >
              <div
                style={{
                  flex: 3,
                  fontSize: 22,
                  fontWeight: "500",
                  display: "flex",
                }}
              >
                Thank You!{" "}
              </div>
              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              ></div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: "bold",
                }}
              ></div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              marginTop: 40,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                // padding: "10px 0",
              }}
            >
              <div
                style={{
                  flex: 3,
                  fontWeight: "500",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    // alignItems: "flex-start",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  {JSON.parse(localStorage.getItem("admin_data"))?.company_name}
                </div>
                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                >
                  {"Contact : " +
                    JSON.parse(localStorage.getItem("admin_data"))?.mobile}
                </div>
                <MyComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
