import { useWindowSize } from "@react-hook/window-size";
import moment from "moment";
import React from "react";
import { FaCrown, FaUserGroup } from "react-icons/fa6";
import { HiMiniRectangleGroup } from "react-icons/hi2";
import { IoIosLock, IoMdRefresh, IoMdSync } from "react-icons/io";
import {
  MdArrowForwardIos,
  MdDashboardCustomize,
  MdFaceUnlock,
  MdLocalMall,
  MdPayments,
  MdPointOfSale,
  MdPowerSettingsNew,
  MdSettings,
} from "react-icons/md";
import { RiProfileLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Backend from "../Backend/Backend";
import MenuButton from "./MenuButton";
import PremiumModal from "./PremiumModal";
import SaleCard from "./SaleCard";
import { Button } from "primereact/button";

const backend = new Backend();

export default function Home() {
  // alert(localStorage.getItem("superAdmin"));
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  const [dashboardData, setDashboardData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openPremiumModal, setOpenPremiumModal] = React.useState(false);
  const scrollHeight = height - 310;

  React.useEffect(() => {
    dashboard();
  }, []);

  const dashboard = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend.dashboard(data).then((r) => {
      if (r.error == "False") {
        setDashboardData(r.admin_data);
      } else {
        window.alert(r.message);
        localStorage.clear();
        window.location.reload();
      }
      setLoading(false);
    });
  };

  const sync_all = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend.sync_all(data).then((r) => {
      if (r.error == "False") {
        localStorage.setItem("products", JSON.stringify(r.products));
        localStorage.setItem("category", JSON.stringify(r.category));
        localStorage.setItem("payment", JSON.stringify(r.payment));
        localStorage.setItem("admin_data", JSON.stringify(r.admin_data));
        window.alert("Sync Completed");
      } else {
        window.alert(r.message);
        // localStorage.clear();
        window.location = "/login";
      }
      setLoading(false);
    });
  };

  const handleLogout = () => {
    if (window.confirm("Do you really want to Logout?")) {
      localStorage.clear();
      window.location.reload();
    }
  };

  return (
    <div style={{ height: height, width: width, overflow: "hidden" }}>
      <div
        style={{
          height: 50,
          padding: 10,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="pcolor"
          style={{
            flex: 1,
            textAlign: "left",
            alignSelf: "center",
            fontSize: 22,
            fontWeight: "bold",
          }}
        >
          Simply Sales
        </div>
        <div style={{ width: 70, alignSelf: "center" }}>
          <div
            style={{
              height: 45,
              // width: 45,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              borderRadius: 100,
              color: "black",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => sync_all()}
            >
              <IoMdSync fontSize={25} />
              <div style={{ fontSize: 12 }}>Sync </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => dashboard()}
            >
              <IoMdRefresh fontSize={25} />

              <div style={{ fontSize: 12 }}>
                {loading ? "Loading" : "Reload"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleLogout()}
            >
              <MdPowerSettingsNew fontSize={25} />
              <div style={{ fontSize: 12 }}>Logout</div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: 50,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="bgp"
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            padding: 10,
            color: "white",
          }}
        >
          Welcome {dashboardData?.company_name}!
          {dashboardData?.admin_premium != 1 ? null : (
            <div onClick={() => navigation("/premium")}>
              <FaCrown color={"gold"} size={24} />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          height: 180,
          overflowX: "scroll",
          overflowY: "hidden",
          padding: 20,
          display: "flex",
          paddingRight: 0,
        }}
      >
        {dashboardData?.admin_premium != 1 ? (
          <SaleCard
            today={"FREE"}
            name="PLAN"
            name2="UPGRADE TO"
            yesterday={
              <Button
                onClick={() => navigation("/premium")}
                label="Premium"
                severity="warning"
                icon={() => (
                  <FaCrown
                    color={"#ffffff"}
                    style={{ marginRight: 5 }}
                    size={18}
                  />
                )}
                raised
                style={{
                  backgroundColor: "gold",
                  borderColor: "gold",
                  height: 30,
                  marginTop: 5,
                  color: "#ffffff",
                }}
              />
            }
            colorb="var(--primary-color)"
          />
        ) : null}
        <SaleCard
          name="TODAY"
          name2="YESTERDAY"
          today={dashboardData?.today_total + " Rs"}
          yesterday={dashboardData?.yesterday_total + " Rs"}
          colorb="var(--primary-color)"
        />
        <SaleCard
          name="TODAY"
          name2="YESTERDAY"
          today={dashboardData?.today_bills + " Bills"}
          yesterday={dashboardData?.yesterday_bills + " Bills"}
          colorb="var(--primary-color)"
        />
        {dashboardData?.admin_premium == 1 ? (
          <SaleCard
            today={moment(dashboardData.expiry_date).diff(moment(), "days")}
            name="DAYS LEFT"
            name2="EXPIRY DATE"
            yesterday={moment(dashboardData.expiry_date).format("DD-MMM-YY")}
            colorb="var(--primary-color)"
          />
        ) : null}
      </div>

      <div
        style={{
          width: "100%",
          marginTop: 20,
          borderTopStyle: "solid",
          borderTopWidth: 0.5,
          height: scrollHeight,
          overflowY: "scroll",
        }}
      >
        <MenuButton
          Icon={() => <MdPointOfSale fontSize={25} />}
          colorb="var(--cyan-400)"
          name="Point Of Sale"
          desc="Make Bills"
          route={() => navigation("/pos")}
        />
        <MenuButton
          Icon={() =>
            dashboardData?.admin_premium == 1 ? (
              <FaUserGroup fontSize={25} />
            ) : (
              <FaCrown fontSize={25} />
            )
          }
          colorb={
            dashboardData?.admin_premium == 1
              ? "var(--orange-400)"
              : "var(--yellow-300)"
          }
          desc={
            dashboardData?.admin_premium == 1 ? "Captains" : "Get Premium Now"
          }
          name={
            dashboardData?.admin_premium == 1 ? "Manages Counter" : "Captians"
          }
          route={() =>
            JSON.parse(dashboardData?.permission)?.add_edit_captain == 1 &&
            dashboardData?.admin_premium == 1
              ? navigation("/captain")
              : dashboardData?.admin_premium != 1
              ? navigation("/premium")
              : window.alert("You do not have enough permission")
          }
          rightIcon={
            dashboardData?.admin_premium != 1 ? (
              <IoIosLock color="var(--yellow-300)" fontSize={25} />
            ) : (
              <MdArrowForwardIos fontSize={25} />
            )
          }
        />
        <MenuButton
          Icon={() => <MdLocalMall fontSize={25} />}
          colorb="var(--green-400)"
          name="Products"
          desc="Manage Products And Inventory"
          route={() => navigation("/products")}
        />
        <MenuButton
          Icon={() => <HiMiniRectangleGroup fontSize={25} />}
          colorb="var(--yellow-400)"
          name="Catagories"
          desc="Manage Catagories/Grouping"
          route={() => navigation("/category")}
        />
        <MenuButton
          Icon={() => <MdPayments fontSize={25} />}
          colorb="var(--teal-400)"
          name="Payment Method"
          desc="Manage Payment Method"
          route={() => navigation("/paymentMethod")}
        />
        <MenuButton
          Icon={() => <MdDashboardCustomize fontSize={25} />}
          colorb="var(--pink-400)"
          name="Reports"
          desc="Bill/Item Wise Reports"
          route={() => navigation("/reports")}
        />
        <MenuButton
          Icon={() => <MdSettings fontSize={25} />}
          colorb="var(--red-400)"
          name="Setting"
          desc="Bill/Printer Settings"
          route={() => navigation("/setting")}
        />
        <MenuButton
          Icon={() => <RiProfileLine fontSize={25} />}
          colorb="var(--red-400)"
          name="Profile"
          desc="Edit Your Details"
          route={() => navigation("/profile")}
        />
      </div>

      <PremiumModal
        closeModal={() => setOpenPremiumModal(false)}
        visible={openPremiumModal}
      />
    </div>
  );
}
