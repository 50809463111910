import { useWindowSize } from "@react-hook/window-size";
import { Card } from "primereact/card";
import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Backend from "../Backend/Backend";
import LOGO from "../Images/Home2.png";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { BeatLoader } from "react-spinners";
import ForgotPassword from "../Forgotpassword/Forgotpassword";

const backend = new Backend();

export default function Login() {
  const navigation = useNavigate();

  const [width, height] = useWindowSize();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    mobile: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const login = () => {
    let data = {
      mobile: formData?.mobile,
      password: formData?.password,
    };
    backend
      .login(data)
      .then((r) => {
        setLoading(false);
        // alert(JSON.stringify(r));
        if (r.error == "False") {
          localStorage.setItem("admin_id", r.admin_id);
          localStorage.setItem("admin_token", r.admin_token);
          localStorage.setItem("printAddress", "1");
          localStorage.setItem("printMobile", "1");
          localStorage.setItem("printPopup", "0");
          localStorage.setItem("printCustomer", "1");
          localStorage.setItem("superAdmin", r.super_admin);
          localStorage.setItem("printDate", "DD-MM-YYYY hh:mm:ss");
          localStorage.setItem("printGapLines", "3");
          localStorage.setItem("payment", JSON.stringify(r.payment));
          localStorage.setItem("admin_data", JSON.stringify(r.admin_data));
          localStorage.setItem("products", JSON.stringify(r.products));
          localStorage.setItem("category", JSON.stringify(r.category));
          localStorage.setItem("IsLoggedIn", "1");
          window.location.href = "/home";
        } else {
          window.alert(r.message);
        }
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
  };

  const toRegister = () => {
    navigation("/register");
  };

  const toForgotpassword = () => {
    navigation("/ForgotPassword");
  };

  return (
    <div style={{ height: height }}>
      <div>
        <img src={LOGO} style={{ width: 300, height: 300 }} />
      </div>
      <div
        style={{
          display: "flex",
          padding: 10,
          fontSize: 20,
          fontWeight: "bold",
        }}
      >
        Login To Your Account
      </div>

      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Mobile
        </div>
        <InputText
          onChange={handleChange}
          type="number"
          id="mobile"
          name="mobile"
          value={formData.mobile}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Password
        </div>
        <InputText
          onChange={handleChange}
          type="password"
          id="password"
          name="password"
          value={formData.password}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          label={loading ? <BeatLoader size={10} color="white" /> : "LOGIN"}
          onClick={() => login()}
          style={{ marginTop: 10, width: "100%", padding: 10 }}
        />
      </div>

      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <div style={{ justifySelf: "center" }}> Forgot Password?</div>
        <Button
          text
          label="Reset Password"
          onClick={() => toForgotpassword()}
        />
      </div>

      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <div style={{ justifySelf: "center" }}> Dont Have Account?</div>
        <Button text label="Signup" onClick={() => toRegister()} />
      </div>
    </div>
  );
}
